.image-slider {
  overflow: hidden !important;
}

.active-image-container {
  height: 500px !important;

  &.--mobile {
    height: auto !important;
  }

  img {
    transition: transform 0.5s ease !important;
  }
}

.thumbnail-slider {
  position: relative !important;
}

.thumbnails {
  transition: transform 0.3s ease !important;

  /* Adjust the duration and timing function as needed */

  .thumbnail {
    width: 10% !important;
    height: 70px !important;
    margin-right: 5px !important;
    cursor: pointer !important;
    border: 2px solid #fff !important;
    transition: border-color 0.3s ease !important;
    object-fit: cover !important;

    &:hover {
      border-color: #3498db !important;
    }

    &.selected {
      border-color: #ffc000 !important;
    }
  }
}

.left {
  left: 10px !important;
}

.right {
  right: 10px !important;
}

.slider-icon {
  margin: 0 5px !important;
  transform: translateY(-50%) !important;
  font-size: 20px !important;
  cursor: pointer !important;
  z-index: 1 !important;
}

@media (max-width: 600px) {
  .thumbnail {
    width: 40px !important;
    height: 40px !important;
  }
}
