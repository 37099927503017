// Below styles are overriding 'react-images-viewer' third party library.
#viewerBackdrop {
  display: flex;
  flex-direction: column;

  figure {
    overflow: hidden;

    img {
      transition: transform 0.3s ease-out;
    }
  }

  .custom-controls {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    z-index: 1;

    button svg:hover circle {
      fill: #00558c;
    }
  }

  div[class^='header_'],
  & > :first-of-type > :first-of-type {
    justify-content: flex-end;
    align-items: center;
  }

  div[class^='content'],
  & > div:first-of-type {
    margin-bottom: 0 !important;
  }

  div[class^='paginatedThumbnails_'],
  & > div:nth-child(2) {
    position: unset;
    transform: translateX(0%);
  }

  & > button[class^='arrow'][class*='left'],
  & > button[class*='1siykgqh'] {
    left: 12%;
  }

  & > button[class^='arrow'][class*='right'],
  & > button[class*='1ru5avoi'] {
    right: 12%;
  }
}
