@use '~@drivably-apps/component-lib/dist/styles/util/mixins';
@use '~@drivably-apps/component-lib/dist/styles/util/variables';

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'medium' {
    @media (min-width: 768px) {
      @content;
    }
  }
}

.lead-selection {
  width: 100vw;
  max-width: 100%;
  @include respond-to('medium') {
    width: 380px;
  }
}

.vehicle {
  &-carfax {
    border: 1px solid #dcdfe8;

    &--modal {
      background-color: #fafaf9;
      max-width: 400px;
    }
  }

  &-detail {
    height: calc(100vh - 64px);

    &__information {
      border: 1px solid var(--drvtrd-clr-base-lt);
      box-shadow: 00 0 2px 0 rgb(0 0 0 / 20%);
    }

    &__content-section-main,
    &__loader {
      max-width: 1200px !important;
      width: 100%;
      padding: 0 1rem;

      @include respond-to('medium') {
        padding: 0;
      }
    }

    &__wrapper {
      width: 100%;
    }
  }
}

.vehicle-stock-information {
  &__car-info,
  &__customer-info {
    @include respond-to('medium') {
      max-width: 50%;
    }
  }

  &__modal {
    width: 95vw;
    @include respond-to('medium') {
      width: 600px;
    }

    &-wrapper {
      width: 100%;
      @include respond-to('medium') {
        width: 50%;
      }
    }
  }
}

.form-control-input .MuiInputBase-root {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

@media only screen and (max-width: 600px) {
  .MuiDialog-container .MuiPaper-root.clcui-modal__paper {
    max-width: 90% !important;
    max-height: 90% !important;
  }

  .MuiToolbar-root {
    padding-right: 0 !important;
  }
}

body {
  padding-right: 0 !important;
  overflow: auto !important;
}

.clearcar-vehicle-card-mobile {
  .MuiButtonBase-root.clcui-btn-base {
    display: contents !important;
  }
}
